import { LoginOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import React from "react";
import { clearLocalStorage } from "../util/localstorage.util";
const { Header } = Layout;

function NavBar() {

    const logoutHandler = () => {
        clearLocalStorage();
        window.location.reload();
    }

  const menu = (
    <Menu style={{ width: "125px" }}>
      <Menu.Item onClick={logoutHandler}>
        <LoginOutlined /> Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header" style={{ padding: 0 }}>
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ height: "100%" }}
      >
        <Dropdown overlay={menu} trigger={["click"]}>
          <Avatar className="icon-avatar" size="large">
            A
          </Avatar>
        </Dropdown>
      </div>
    </Header>
  );
}

export default NavBar;
