import "antd/dist/antd.css";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.variable.min.css";
import Routes from "./routes";
import "./assets/sass/main.scss";
import { ConfigProvider } from "antd";

ConfigProvider.config({
  theme: {
    primaryColor: "#81298f",
  },
});

function App() {
  return (
    <BrowserRouter> 
      <Routes />
    </BrowserRouter> 
  );
}

export default App;
