import { ILoginResponse } from "../interface/auth.interface";

export const addUserToStorage = (data:ILoginResponse) => {
    localStorage.setItem("user", JSON.stringify(data));
}

export const getAccessToken = () => {
    const token = localStorage.getItem("user"); 
    if(token) {
        try {
          const user = JSON.parse(token);
          if(user?.xs) return user.xs;
          else return null;
        } catch (error) {
          return null;  
        } 
    }
    else {
        return null
    }
}

export const clearLocalStorage = () => {
  localStorage.removeItem("user");
}