import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { IRouteItem } from "../interface/routes.interface";

const HomePage = lazy(() => import("../pages/Manage/HomePage"));
const StudyPage = lazy(() => import("../pages/Study/Study"));
const EditStudyPage = lazy(() => import("../pages/Study/EditStudyPage"));
const ChangePassword = lazy(() => import("../pages/ChangePassword/ChangePassword"));

const dashboardRoutesList: IRouteItem[] = [
  {
    component: HomePage,
    label: "Manage Home Page",
    path: "/",
    exact: true
  },
  {
    component: StudyPage,
    label: "Manage Study Page",
    path: "/study",
    exact: true
  },
  {
    component: EditStudyPage,
    label: "Edit Study Page",
    path: "/study/:name",
    exact: true
  },
  {
    component: StudyPage,
    label: "Manage Study Page",
    path: "/preparation",
    exact: true
  },
  {
    component: EditStudyPage,
    label: "Edit Study Page",
    path: "/preparation/:name",
    exact: true
  },
  {
    component: ChangePassword,
    label: "Change Password",
    path: "/change-password",
    exact: true
  },
];

function DashboardRoutes() {
  const flatRoutes = dashboardRoutesList.map((route, index) => (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route}/>}
    />
  ));

  return flatRoutes;
}

export default DashboardRoutes();