import React, { lazy } from "react";
import { Route } from "react-router-dom";
import { IRouteItem } from "../interface/routes.interface";

const LoginPage = lazy(() => import("../pages/Login/Login"));

const loginRoutesList: IRouteItem[] = [
  {
    component: LoginPage,
    label: "Login",
    path: "/login",
    exact: true
  },
];

function LoginRoutes() {
  const flatRoutes = loginRoutesList.map((route) => (
    <Route
      path={route.path}
      exact={route.exact}
      key={route.path}
      render={(props) => <route.component {...props} routes={route}/>}
    />
  ));

  return flatRoutes;
}

export default LoginRoutes();
