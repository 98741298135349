import { Layout, Menu, Typography } from "antd";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import logo from "../assets/img/logo.png";

const { Sider } = Layout;

interface IProps {
  collapsed: boolean;
  onCollpase: (collpaseValue: boolean) => void;
}

function SideNav({ collapsed, onCollpase }: IProps) {
  const location = useLocation();
  const history = useHistory();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const linkChangeHandler = (pathName: string) => {
    setActiveLink(pathName);
    history.push(pathName);
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollpase}>
      <img className="logo" src={logo} />
      <Typography.Text type="secondary" className="menu-title">
        Manage Pages
      </Typography.Text>
      <Menu className="nav-menu" activeKey={activeLink}>
        <Menu.Item key="/" onClick={() => linkChangeHandler("/")}>
          <i className="icofont-home"></i> Home Page
        </Menu.Item>
        <Menu.Item key="/study" onClick={() => linkChangeHandler("/study")}>
          <i className="icofont-book-alt"></i> Study Page
        </Menu.Item>
        <Menu.Item
          key="/preparation"
          onClick={() => linkChangeHandler("/preparation")}
        >
          <i className="icofont-listening"></i> Preparation Page
        </Menu.Item>
        <Typography.Text type="secondary" className="menu-title">
          Setting
        </Typography.Text>
        <Menu.Item
          key="/change-password"
          onClick={() => linkChangeHandler("/change-password")}
        >
          <i className="icofont-settings-alt"></i> Change Password
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default SideNav;
