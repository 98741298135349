import { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import LoginRoutes from './LoginRoutes'
import MainLayout from './MainLayout'

function Routes() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
        <Switch>
            {LoginRoutes}
            <Route component={MainLayout}/>
        </Switch>
    </Suspense>
  )
}

export default Routes