import { Avatar, Layout } from "antd";
import { useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import NavBar from "../components/Header";
import SideNav from "../components/SideNav";
import { getAccessToken } from "../util/localstorage.util";
import DashboardRoutes from "./DashboardRoutes";

const { Content } = Layout;

function MainLayout() {
  const [collapsed, setCollapsed] = useState(false);

  const onCollpase = (collpaseValue: boolean) => {
    setCollapsed(collpaseValue);
  };

  if(!getAccessToken()) {
    return <Redirect to="/login"/>
  }

  return (
    <Layout style={{ minHeight: "100vh" }} className="layout-wrapper">
     <SideNav collapsed={collapsed} onCollpase={onCollpase}/>
      <Layout>
        <NavBar />
        <Content style={{ margin: "0 16px" }}>
          <Switch>{DashboardRoutes}</Switch>
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
